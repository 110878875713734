$(document).on('turbolinks:load', () => {
  if($(window).width() < 1200) {
    $('.nav-treeview').addClass('hidden-submenu');
  }
  $('.submenu-header').on("click", function() {
    child = $(this).children('i').first();
    sibling = $(this).parent('.parent-menu').children('ul');

    parent_menu_siblings = $(this).parent('.parent-menu').siblings('.parent-menu');
    $(parent_menu_siblings).each(function(i) {
      $(this).children('ul').addClass('hidden-submenu');
      $(this).children('i').first().removeClass('fa-angle-up');
      $(this).children('i').first().addClass('fa-angle-down');
    });

    if(sibling.hasClass('hidden-submenu')){
      sibling.removeClass('hidden-submenu');
      child.removeClass('fa-angle-down');
      child.addClass('fa-angle-up');
    } else {
      sibling.addClass('hidden-submenu');
      child.removeClass('fa-angle-up');
      child.addClass('fa-angle-down');
    }
  });

  $(window).on('resize', function() {
    if($(window).width() < 1200) {
      $('.nav-treeview').addClass('hidden-submenu');
    }
  });
});
