$(document).on('turbolinks:load', () => {
  $(function() {
    $('body').on('click', '[data-toggle="popover"]', function(e){
      var _this = $(this);
      HidePopovers();

      _this.popover(
      {
        html:true,
        trigger: 'manual',
        placement: _this.data('placement')
      }).popover('show');

      _this.attr('popover-state', 1);
      _this.on('click', PopoverClick);
    });
  });
  $('body').on('click', function (e) {
    if (($(e.target).data('toggle') !== 'popover')) {
      HidePopovers();
    }
  });
});

function PopoverClick()
{
  if($(this).attr('popover-state') == '0') {
    HidePopovers();
    $(this).attr('popover-state', 1);
    $(this).popover('show');
  } else {
    HidePopovers();
  }
}

function HidePopovers()
{
  elem = $('[data-toggle="popover"][popover-state="1"]');
  elem.popover('hide');
  elem.attr('popover-state', 0);
}
