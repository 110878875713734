// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("@rails/ujs").start()
require("channels")
require('popper.js/dist/umd/popper')
require('bootstrap')
require('admin-lte')
// const $ = require('jquery')
// require('select2')
require('./lib/ddslick')
require('./popovers')
require('./left_menu')
require('./popovers')

import jquery from 'jquery';
import jQuery from 'jquery';
import $ from 'jquery';

import select2 from 'select2';

require("./lib/modals")

import "chartkick/highcharts"
import("chartkick/chart.js")

require("summernote/dist/summernote-bs4.min")
require("summernote/dist/lang/summernote-ca-ES.min")
require("summernote/dist/lang/summernote-es-ES.min")
require("summernote/dist/lang/summernote-en-US.min")

$(document).ready(() => {

});

$(document).on('turbolinks:load', () => {
  const currentLocale = $('meta[name=locale]').attr('content');
  let firstLoad = false;

  $('#language').ddslick({
    data: [],
    width: 60,
    background: 'white',
    onSelected: function (data) {
      const locale = data.selectedData.value;

      if (currentLocale !== locale && firstLoad) {
        window.location = `/locale/${locale}`;
      }
      if(!firstLoad) firstLoad = true;
    }
  });

  $('.select2').select2({ height: '38px' });

  $('#q_active').on('change', (v) => {
    $('#user_search').submit()
  });

  $('.filter').on('click', () => $('.filter-container').toggleClass('visible'));

  //autosubmit for /articles category filter
  $('#q_article_category_id_eq').change(function (data) {
    $(this).parents('form:first').submit();
  });

  //autosubmit employee search
  $('.filter-container input[type=checkbox]').on('click', () => $('#user_search').submit())
  $('.filter-container input[type=checkbox]').on('click', () => $('#workplace_search').submit())
  $('.filter-container input[type=checkbox]').on('click', () => $('#festive_search').submit())

});

$(document).on('ready turbolinks:load', function() {
  $("#search-form input").on("keyup", function() {
    $.get($('#search-form').attr("action"), $('#search-form').serialize(), null, "script");
    return false;
  });
  var document_sending = $('.seeDocument')
  if (document_sending.length > 0) {
    document_sending.click((a) => {
      let link = $(a.currentTarget)
      if (link.data('send') == true) {
        $.ajax({
          url: "/deliveries/" + link.data('id') + "/date_update",
          type: "POST",
          data: {

          },
          success: function(resp){ }
        });
      }
    });
  }
  var document_accepting = $('.acceptButton')
  if (document_accepting.length > 0) {
    document_accepting.click((a) => {
      let link = $(a.currentTarget)
      $.ajax({
        url: "/deliveries/" + link.data('id') + "/accept_update",
        type: "POST",
        data: {

        },
        success: function(resp){
          location.reload();
        }
      });
    });
  }
});


Chartkick.options = {
  library: {
    xAxis: {
        labels: {
            enabled: true,
            formatter: function() {
              if (Number.isInteger(this.value) && (this.value).toString().length > 4) {
                // if an integer and more than 4 digits
                // unixtime value, but not a year
                return ("0" + ((new Date(this.value * 1000)).getMonth() + 1)).slice(-2) + "-" + (new Date(this.value * 1000)).getFullYear();
              } else {
                return this.value;
              }
            },
        },
    },
    tooltip: {
      formatter: function(tooltip) {
        if (tooltip.isNull) {
          return 'Null';
        }
        tooltip = tooltip.defaultFormatter.call(this, tooltip)
        var tooltip_new = []
        if (Array.isArray(tooltip)) {
          var pos1 = tooltip.shift();
          var pos2 = tooltip.shift();
          var pos3 = tooltip.shift();

          tooltip_new[0] = pos2;
          tooltip_new[1] = pos3;
          tooltip[0] = tooltip_new;
        }
        var tooltip_code = tooltip[0].join('')

        if (Number.isInteger(this.x) && (this.x).toString().length > 4) {
          // if an integer and more than 4 digits
          // unixtime value, but not a year
          var th_total = "";
          if ($("#moods-years").length == 1 || this.total.toString().indexOf(".") !== -1) {
            // Decimals means percentages, at the moment
            th_total = Math.round(this.total).toString() + "%";
          } else {
            th_total = this.total;
          }
          return  '<b>' + ("0" + ((new Date(this.x * 1000)).getMonth() + 1)).slice(-2) + "-" + (new Date(this.x * 1000)).getFullYear() + '</b></br>· Total ' + th_total + '</br>' + tooltip_code;
        } else if (this.series.options.type == 'pie') {
          var return_value = '<b>' + this.key + '</b></br>';
          if (this.total !== 'undefined') {
            var th_total = "";
            // pie in percentage at the moment
            th_total = Math.round(this.total).toString() + "%";
            return_value = return_value + '· Total ' + th_total + '</br>';
          }
          return_value = return_value + tooltip_code.replace('Value:', '') ;
          return  return_value;
        } else {
          var return_value = '<b>' + this.x + '</b></br>';
          if (this.total !== 'undefined') {
            var th_total = "";
            if ($("#moods-months").length == 1 || this.total.toString().indexOf(".") !== -1) {
              // Decimals means percentages, at the moment
              th_total = Math.round(this.total).toString() + "%";
            } else {
              th_total = this.total;
            }
            return_value = return_value + '· Total ' + th_total + '</br>';
          }
          return_value = return_value + tooltip_code;
          return  return_value;
        }
      },
    },
    plotOptions: {
      column: {
        tooltip: {
          headerFormat: '<b>{point.key}</b></br>· Total {point.total}</br>'
        },
        dataLabels: {
          formatter: function() {
            var th_y = "";
            if ($("#moods-years").length == 1 || (typeof(this.y) === 'number' && this.y.toString().indexOf(".") !== -1)) {
              // it's a decimal number
              th_y = (this.y != 0) ? this.y + "%" : "";
            } else {
              // it's a integer
              th_y = (this.y != 0) ? this.y : "";
            }
            return th_y;
          },
          enabled: true,
          overflow: 'none'
        }
      },
      pie: {
        showLegend: false,
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b></br>{point.percentage:.1f}%"
        }
      }
    }
  }
}
